@font-face {
  font-family: Euclid-Bold;
  src: url('./assets/fonts/Euclid Circular A Bold.ttf');
}

@font-face {
  font-family: Euclid-Medium;
  src: url('./assets/fonts/Euclid Circular A Medium.ttf');
}

@font-face {
  font-family: Euclid-Light;
  src: url('./assets/fonts/Euclid Circular A Light.ttf');
}

@font-face {
  font-family: Euclid-Regular;
  src: url('./assets/fonts/Euclid Circular A Regular.ttf');
}

body, html {
  box-sizing: border-box;
  overflow-x: hidden;
  margin: 0; padding: 0;
  background-color: #f0f0f0;
  /* background-color: #333333; */
  width: 100%;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.DatePicker-Input .MuiInputBase-root{
  background-color: #252525!important;
  border-radius: 15px!important;
  width: 90%!important;
  margin: 8px!important;
}

.MuiInputBase-input, .MuiFormLabel-root{
  /* color:#ffffff!important; */
  font-family:'Euclid-Bold'!important;
  cursor: pointer;
}

.MuiInputBase-input{
  font-size: 0.9rem!important;
  text-align: center!important;
}

.MuiFormLabel-root{
  font-size: 1rem!important;
}

.MuiTextField-root{
  margin: 8px!important;
}

.MuiAutocomplete-input {
  color: #fff !important;
  min-width: fit-content!important;
}
/* 
.MuiOutlinedInput-root {
  border: 1px dashed red!important;
} */


/* SCROLL BAR */

/* width */
::-webkit-scrollbar {
  width: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #888; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background-color: #333; 
  transition: background-color 1s ease;
  -webkit-transition: background-color 1s ease;
  -moz-transition: background-color 1s ease;
  -o-transition: background-color 1s ease;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: #2f9f48; 
}



/* MEDIA QUERIES */

/* xs and sm screens only */
/* @media(max-width:900px){
  .MuiTextField-root{
    margin: 8px 0 6px 0!important;
  }
} */


/* md and lg screens only */
@media(min-width:900px){
  /* .MuiTabs-flexContainer :first-child{
    border-radius: 8px 0 0 8px!important;
  }
  .MuiTabs-flexContainer :last-child{
    border-radius: 0 8px 8px 0!important;
  } */
}
